* {
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  /* -khtml-user-select: none; */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scrollbar-width: none;
}

.ql-editor {
  user-select: text;
}

/* IOS FIX */
input,
textarea {
  /* Exception for input areas */
  -webkit-touch-callout: default !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
}

/* IOS PREVENT ZOOM */
input,
select {
  font-size: 100%;
}

body {
  font-family: "rns";
  background-color: #ffffff;
  background-image: url("/images/brilliant.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

/* ANIMATION & TRANSITION STYLE */
.alert-enter {
  opacity: 0;
}

.alert-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 1;
}

/* INPUT */
input[type="text"],
select {
  width: 100%;
  height: 35px;
  border-radius: 0px;
  border: none;
  padding-left: 5px;
}

select {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url("/images/down-arrow.png") no-repeat;
  background-position: right;
  display: inline-block;
  appearance: none;
}

/* repair blur */

* {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

button {
  outline: none;
  background: transparent;
  border: 1px solid transparent;
}

button:active {
  outline: none;
  background: transparent;
  border: 1px solid grey;
}

input,
input:focus {
  outline: none;
  background: transparent;
  border: 1px solid transparent;
}

input:active {
  outline: none;
  background: transparent;
  border: 1px solid grey;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@keyframes pulse {
  to {
    transform: scale(3);
    opacity: 0;
  }
}

@media print {
  body {
    visibility: hidden;
    margin: 0;
    color: #000;
    background-color: #fff;
  }
  @page {
    margin: 0.25in;
  }

  table,
  figure {
    page-break-inside: avoid; /* Prevent the table from breaking across pages */
  }
  .admin {
    visibility: visible;
    margin: 0 !important;
  }
}
